/*-----------------------------------------------
 共通JS
-----------------------------------------------*/

jQuery(function($){

// header
//-----------------------------------------------

//トグルメニュー
  var $header = $('#header');
  $('#nav-toggle').click(function(){
      $header.toggleClass('open');
  });

//言語設定が1言語の時のみheaderの言語切り替えプルダウンを非表示に
  var lang_box = $(".selectList>li:eq(0)");
  var lang_num = $("select#language option").length;
  if ( lang_num < 2 ){lang_box.hide();}

// footer
// -----------------------------------------------

// ページトップへ戻るのスクロール
  var $pagetop = $('#pageTop a');

  if ($(window).width() > 768) $pagetop.hide();
  $(window).scroll(function(){
    if($(window).scrollTop() > 200){
      $pagetop.fadeIn();
    } else {
      $pagetop.fadeOut();
    }
  });

//    Lowers
// -----------------------------------------------

//デフォルトテーマのカートボタンの画像を見た目調整のため非表示に調整する
  var cartBtn = $('input[src$="btn_put_cart.png"]'); //カートに入れるボタン
  var cartBtn_attr = cartBtn.attr('src');//カートボタンの画像の属性
  var ImgSrc = 'resources/c_media/themes/theme_0/lang/**/btn_put_cart.png';//カートに入れるボタンの画像
  if( cartBtn_attr = ImgSrc){
    cartBtn.removeAttr('src');
  }

//下層のキャプションのクラス操作
    var lt = $(".lowerTitle");
    var cat = $("ul.topiclist li:eq(2)");//パンクズの２つめの文字列で判定する
    var catTxt = cat.text();

    if ( lt.length > 0){
      switch ( catTxt ) {//[商品一覧][商品詳細]下層キャプションに背景画像変更のクラスを指定する
        case 'APPARATUS':
          lt.addClass('bg_apparatus');
          break;
        case 'GLASS PARTS':
          lt.addClass('bg_glassparts');
          break;
        case 'OTHERS':
          lt.addClass('bg_others');
          break;
        default:
          lt.addClass('bg_apparatus');
          break;
      }

      //サイドナビの親カテにクラス付与
      $(".parent_list:contains('"+catTxt+"')").children('a').addClass('active');

      //サイドナビの子カテにクラス付与
      var subcat = $("ul.topiclist li:eq(4)");//パンクズの２つめの文字列で判定
      var subcatTxt = subcat.text();
      if ( subcat.length >0 ){
        $(".parent_list ul li a:contains('"+subcatTxt+"')").addClass('active');
      }

    }
    //--endif ( lt.length>0 )

});
